.border-item {
  ::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px;
    padding: 2px;
    background: linear-gradient(104.6deg, #FF648B 0.89%, #FFA564 16.85%, rgba(255, 255, 255, 0) 51.7%, #39BDFF 93.01%, #6B39FF 113.09%, #6B39FF 113.09%);
    mask:
        linear-gradient(#000 0 0) content-box,
        linear-gradient(#000 0 0);
    mask-composite: exclude;
  }
}

.animated-text {
  animation: fade-slide 0.5s ease-in-out;
}

/* Fade in and slide down */
@keyframes fade-slide {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}